import React, { useState, useEffect } from "react";
import classes from "./Welcome.module.less";
import { Col, Row, DatePicker } from "antd";
import FinanceSummary from "./FinanceSummary/FinanceSummary";
import DistributionPerformance from "./DistributionPerformance/DistributionPerformance";
import PaymentsReceived from "./PaymentsReceived/PaymentsReceived";
import LocationStatistics from "./LocationStatistics/LocationStatistics";
import CategoryStatistics from "./CategoryStatistics/CategoryStatistics";
import LocationsMap from "./LocationsMap/LocationsMap";
import MonetizationStatistics from "./MonetizationStatistics/MonetizationStatistics";
import TransactionsChart from "./TransactionsChart/TransactionsChart";
import AvgUserPerWeekday from "./AvgUserPerWeekday/AvgUserPerWeekday";
import MarketingPerformance from "./MarketingPerformance/MarketingPerformance";
import moment from "moment";

const { MonthPicker } = DatePicker;

export default () => {
  let [tokenAssigned, setTokenAssigned] = useState(false);
  let [month, setMonth] = useState(new Date().getMonth());
  let [year, setYear] = useState(new Date().getFullYear());
  let [loadingPayments, setLoadingPayments] = useState(true);
  let [payments, setPayments] = useState([]);
  let [locations, setLocations] = useState(null);
  let [loadingLocations, setLoadingLocations] = useState(true);
  let [monetizationData, setMonetizationData] = useState([]);

  const handleDatePicker = (date) => {
    setMonth(date.toDate().getMonth());
    setYear(date.toDate().getFullYear());
    setLoadingPayments(true);
  };

  const handlePaymentsReceived = (paymentsReceived) => {
    setLoadingPayments(false);
    setPayments(paymentsReceived);
  };

  const handleLocationsReceived = (locationsReceived) => {
    setLoadingLocations(false);
    setLocations(locationsReceived);
  };

  const handleMonetizationReceived = (data) => {
    setMonetizationData(data);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      let token = localStorage.getItem("id_token");
      if (token) {
        clearInterval(interval);
        setTokenAssigned(true);
      }
    }, 50);
  }, []);

  return tokenAssigned ? (
    <div className={classes.Container}>
      <Row className={classes.pickercontainer} type="flex" justify="end">
        <MonthPicker
          value={moment(`${year}-${month + 1}-01`)}
          onChange={handleDatePicker}
          allowClear={false}
          placeholder="Select month"
        />
      </Row>
      <Row gutter={[16, 16]} style={{ padding: "16px 0px" }}>
        <Col className={classes.finance} xl={8} lg={12} md={12} sm={24} xs={24}>
          <FinanceSummary
            month={month}
            year={year}
            handlePaymentsReceived={handlePaymentsReceived}
            handleMonetizationReceived={handleMonetizationReceived}
          />
        </Col>
        <Col className={classes.finance} xl={8} lg={12} md={12} sm={24} xs={24}>
          <DistributionPerformance month={month} year={year} />
        </Col>
        <Col className={classes.finance} xl={8} lg={24} md={24} sm={24} xs={24}>
          <TransactionsChart
            month={month}
            year={year}
            loading={loadingPayments}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ padding: "16px 0px" }}>
        <Col span={24}>
          <MarketingPerformance month={month} year={year} />
        </Col>
      </Row>
      <Row gutter={16} style={{ paddingBottom: "16px" }}>
        <Col span={24}>
          <PaymentsReceived
            month={month}
            year={year}
            loading={loadingPayments}
            payments={payments}
          />
        </Col>
      </Row>
      <div className={classes.TwoCardsRow}>
        <LocationStatistics handleLocationsReceived={handleLocationsReceived} />
        <CategoryStatistics />
      </div>
      <Row gutter={16} style={{ marginTop: 15 }}>
        <Col span={24}>
          <LocationsMap countries={locations} loading={loadingLocations} />
        </Col>
        <Col span={24}>
          <br />
          <AvgUserPerWeekday />
        </Col>
      </Row>
    </div>
  ) : null;
};
