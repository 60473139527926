import React, { useState, useEffect } from "react";
import { Button, Table, Popconfirm, notification, Row } from "antd";
import {
  getNewsletter,
  deleteNewsletter,
} from "../../../../services/welcomePageService";
import moment from "moment";
import { downloadCSV } from "../../../../functions";
export default () => {
  const [loading, setLoading] = useState(false);
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await getNewsletter();
      if (data && data.newsletters) {
        setData(data.newsletters);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const deleteNewsletterRequest = async (id) => {
    let { data: response } = await deleteNewsletter(id);
    if (response && response.message == "success") {
      getData();
      notification.info({ message: "User removed from Newsletter." });
    } else {
      notification.error({ message: "An error has ocurred." });
    }
  };
  const downloadNewsletterAsCSV = () => {
    try {
      if (data && data.length) {
        setLoadingCsv(true);
        let formatted = data.map((row) => {
          return [row.date, row.email];
        });
        const csvData2 = [["Date", "email"], ...formatted];
        downloadCSV(
          csvData2,
          `Newsletter_signup_${moment().format("YYYY-MM-DD")}.csv`
        );
        setLoadingCsv(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      align: "center",
      defaultSortOrder: "descend",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.date > b.date) return -1;
        else if (a.date < b.date) return 1;
        return 0;
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      align: "center",
      sortDirections: ["descend", "ascend"],
      sorter: (a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) return -1;
        else if (a.email.toLowerCase() < b.email.toLowerCase()) return 1;
        return 0;
      },
    },
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 50,
      render: (id) => (
        <Popconfirm
          title="Are you sure you want to delete this register？"
          onConfirm={() => deleteNewsletterRequest(id)}
        >
          <Button
            ghost
            shape="circle"
            size="small"
            type="danger"
            icon="delete"
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Row type="flex" justify="end" style={{ marginBottom: 20 }}>
        <Button
          icon="download"
          loading={loadingCsv}
          onClick={() => downloadNewsletterAsCSV()}
          type="primary"
          width="150px"
          disabled={loadingCsv}
        >
          Download as CSV
        </Button>
      </Row>
      <Table
        scroll={{ x: 700 }}
        size="small"
        dataSource={data}
        columns={columns}
        loading={loading}
      />
    </div>
  );
};
