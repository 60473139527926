import React, { useState, useEffect } from "react";
import { Table, Icon, Row } from "antd";
import { getMarketingPerformance } from "../../../../services/welcomePageService";
import BarChart from "../../../UI/Charts/Bar/Bar";
import LineChart from "../../../UI/Charts/Line/Line";

export default ({ month, year }) => {
  const [chartType, setChartType] = useState("line");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    chartData: [],
    tableData: [],
  });
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [month]);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await getMarketingPerformance(month + 1, year);
      setData({
        chartData: data.chartData,
        tableData: data.tableData,
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const chartTipeHandle = () => {
    setChartType(chartType === "line" ? "bar" : "line");
  };
  const columns = [
    {
      title: "Date",
      key: "date",
      dataIndex: "date",
      align: "center",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      align: "center",
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      align: "center",
      render: (name) => (name ? name : "-"),
    },
    {
      title: "Company",
      key: "company",
      dataIndex: "company",
      align: "center",
      render: (company) => (company ? company : "-"),
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 700 }}
        size="small"
        dataSource={data.tableData}
        columns={columns}
        loading={loading}
      />
      {!loading ? (
        <>
          <Row type="flex" justify="end">
            <span onClick={chartTipeHandle}>
              <b>
                Show as{" "}
                {chartType === "bar" ? (
                  <Icon type="bar-chart" style={{ fontSize: "16px" }} />
                ) : (
                  <Icon type="line-chart" style={{ fontSize: "16px" }} />
                )}
              </b>
            </span>
          </Row>
          {chartType === "bar" ? (
            <BarChart data={data.chartData} />
          ) : (
            <LineChart data={data.chartData} />
          )}
        </>
      ) : null}
    </div>
  );
};
